import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'



(function () {
  'use strict';
  const glideElement = document.querySelector('.glide')
  if (glideElement){
  new Glide('.glide').mount({ Controls, Breakpoints })
    document.querySelector("html").classList.remove("no-js");
  }
  


})();

jQuery(function ($) {
  let isSpanish = window.location.href.split('/').includes("es");
  let englishSlider = $("#homepage-hero-slider-container");
  let spanishSlider = $("#homepage-hero-slider-container-spanish");

  let englishSliderTopLevel = $("#top-level-hero-slider-container");
  let spanishSliderTopLevel = $("#top-level-hero-slider-container-spanish");

  // top level
  if (isSpanish && spanishSliderTopLevel.length > 0) {
    englishSliderTopLevel.css("display", "none");
    spanishSliderTopLevel.css("display", "");
  }
  //homepage or top level that doesn't have spanish image selected
  else if (isSpanish && spanishSliderTopLevel.length === 0) {
    englishSlider.css("display", "none");
    spanishSlider.css("display", "");
    englishSliderTopLevel.css("display", "");
    spanishSliderTopLevel.css("display", "none");

  } else {
    englishSlider.css("display", "");
    spanishSlider.css("display", "none");
    englishSliderTopLevel.css("display", "");
    spanishSliderTopLevel.css("display", "none");
  }


  $('.homepage-hero-slider-container').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    draggable: false,
    autoplay: true,
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 7000,
  });

  $('.landing-page-hero-slider-container').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    draggable: false,
    autoplay: true,
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 7000,
  });
});
