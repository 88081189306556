/* eslint-disable no-console */
// var AOS = require("aos");
const interactiveMenuItems = document.querySelectorAll(
  ".it-screen-menu .menu-item>a"
);
const navMenuInput = document.querySelector(".it-nav-input");
const menuTrigger = document.querySelector(".it-menu-trigger");
const menu = document.querySelector(".nav-interactive");

menuTrigger &&
  window.addEventListener("DOMContentLoaded", () => {
    const animationOutElements = document.querySelectorAll(".sb-animation-out");
    const cardLinks = document.querySelectorAll(".single-link");
    const itCards = document.querySelectorAll(".interactive-guide-card");
    const itHeaders = document.querySelectorAll(".it-header");
    const itScreen = document.querySelectorAll(".it-screen");
    const page = document.querySelector("body");
    const menuLinks = document.querySelectorAll(".menu-item");
    let screenSize = window.matchMedia("(max-width: 1024px)");

    function scrollToHash() {
      const hashElement = document.getElementById(
        window.location.hash.replace("#", "")
      );
      if (!hashElement) return;
      const rect = hashElement.getBoundingClientRect();

      if (rect.top > this.window.innerHeight < 2 || rect.bottom < 0) {
        hashElement.scrollIntoView();
      }
    }

    window.addEventListener("pageshow", scrollToHash);
    window.addEventListener("hashchange", scrollToHash);
    
    
    window.onpageshow = function(event) {
    if (event.persisted) {
      setTimeout(function() {
        clearAnimationOut(document.querySelectorAll(".sb-animation-out"));
      }, 50);
    }
};
    function clearAnimationOut(elements){
       elements.forEach((element) => {
        element.classList.remove("sb-animation-out");
      });
    }
    if (animationOutElements) {
      animationOutElements.forEach((element) => {
        element.classList.remove("sb-animation-out");
      });
    }
    
    let options;

    if (screenSize) {
      options = {
        rootMargin: "0px",
        threshold: 0.5,
      };
    } else {
      options = {
        rootMargin: "0px 0px 25% 0px",
        threshold: [0, 0.01, 0.5, 0.75, 1],
      };
    }

    cardLinks.forEach((cardLink) => {
      cardLink.addEventListener("click", (e) => {
        animationOut(e, cardLink);
      });
    });

    function randomNumber(max) {
      return Math.floor(Math.random() * (max - 0)) + 0;
    }

    function animationOut(e, link) {
      e.preventDefault();
      let history = link.getAttribute("data-history");
      if (!link.target) {
        const timeOut = setTimeout(() => {
          window.location.assign(history);
          window.location.href = link.href;
        }, 500);
        itCards.forEach((cardLink) => {
          setTimeout(() => {
            cardLink.classList.add("sb-animation-out");
          }, randomNumber(500));
        });
        itHeaders.forEach((itHeader) => {
          itHeader.classList.add("sb-animation-out");
        });
        return;
      } else {
        const timeOut = setTimeout(() => {
          window.open(link.href, "_blank");
        }, 500);
      }
    }
    // eslint-disable-next-line compat/compat
    const screenObserver = new IntersectionObserver(
      backToTopIntersectionCallback,
      options
    );
    // eslint-disable-next-line compat/compat
    const menuObserver = new IntersectionObserver(
      menuIntersectionCallback,
      options
    );

    function backToTopIntersectionCallback(entries) {
      entries.forEach((entry) => {
        setBackToTopState(entry);
        setHashFromScreen(entry);
      });
    }

    function menuIntersectionCallback(entries) {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute("id");

        let menuItem = document.querySelector(`a[href="#${id}"]`);
        if (entry.intersectionRatio > 0.5) {
          let targetEntry = entry.target;
          let targetParent = entry.target.parentElement.previousElementSibling;
          let targetSibling = entry.target.nextElementSibling;

          setTimeout(() => {
            targetEntry.classList.add("sb-animation");

            if (!targetParent.classList.contains("sb-animation")) {
              targetParent.classList.add("sb-animation");
            }
          }, randomNumber(500));

          menuItem && menuItem.classList.add("active");
        } else {
          menuItem && menuItem.classList.remove("active");
        }
      });
    }
    // Track tags with class of .interactive-guide-card
    itCards.forEach((card) => {
      menuObserver.observe(card);
    });
    itScreen.forEach((screen) => {
      screenObserver.observe(screen);
    });

    menuTrigger &&
      menuTrigger.addEventListener("click", () => {
        menu.classList.toggle("open");
        menuTrigger.classList.toggle("open");
      });
    interactiveMenuItems.forEach((interactiveMenuItem) => {
      interactiveMenuItem.addEventListener("click", () => {
        navMenuInput.checked = false;
        menu.classList.remove("open");
        menuTrigger.classList.toggle("open");
      });
    });
    const scrollElement = document.querySelector(
      ".interactive-guide-scroll-snap"
    );
    const logoImg = document.querySelector(".it-logo");
    const backToTopDesktop = document.querySelector(".it-desktop-back-to-top");
    const elementScroll = scrollElement.scrollTop;

    if (page.classList.contains("interactive-guide-page")) {
      scrollElement.addEventListener("scroll", function () {
        if (scrollElement.scrollTop > 1) {
          logoImg.classList.add("scrolled");
          backToTopDesktop.classList.add("scrolled");
        } else {
          logoImg.classList.remove("scrolled");
          backToTopDesktop.classList.remove("scrolled");
        }
      });
    }
  });

function setBackToTopState(entry) {
  const id = entry.target.getAttribute("id");
  let backToTop = document.querySelector(".back-to-top");
  if (entry.intersectionRatio > 0.5) {
    let menuItem = document.querySelector(`a[href="/interactive-guide/#${id}"]`);
    if (id === "top") {
      backToTop.classList.remove("carret-up");
    }
    if (menuItem) {
      menuItem.parentElement.classList.add("active");
    }
  } else {
    let menuItem = document.querySelector(`a[href="/interactive-guide/#${id}"]`);
    if (id === "top") {
      backToTop.classList.add("carret-up");
    }
    if (menuItem) {
      menuItem.parentElement.classList.remove("active");
    }
  }
}

let lastHash = "";
function setHashFromScreen(entry) {
  if (entry.intersectionRatio > 0.5) {
    const id = entry.target.getAttribute("id");
    const hash = id === "top" ? "#" : "#" + id;
    if (hash === lastHash) return;
    lastHash = hash;
    setTimeout(function () {
      history.pushState({}, "", hash);
    }, 700);
  }
}
