const events = ["pagehide", "pageshow"];

const eventLogger = (event) => {
  switch (event.type) {
    case "pagehide": {
      if (document.querySelector("#nav-primary-trigger")) {
        let menuChecked = document.querySelector("#nav-primary-trigger");
        let menuTrigger = document.querySelector(".menu-trigger");
        const translateContainer = document.querySelector(".g-translate-container")
        const bodyTag = document.querySelector("body");
         menuChecked.checked = false;
         menuTrigger.classList.remove("close");
         translateContainer.classList.remove("menu-opened");
         bodyTag.classList.remove("body-menu-opened");
      }
    }
    case "pageshow": {
      if (document.querySelector("#nav-primary-trigger")) {
        let menuChecked = document.querySelector("#nav-primary-trigger");
        let menuTrigger = document.querySelector(".menu-trigger");
        menuChecked.checked = false;
        menuChecked.disabled = false;
        menuTrigger.addEventListener("click", () => {
          const translateContainer = document.querySelector(".g-translate-container")
          const bodyTag = document.querySelector("body");
          menuChecked = document.querySelector("#nav-primary-trigger");
          menuTrigger = document.querySelector(".menu-trigger");
          if (menuChecked.checked) {
            menuTrigger.classList.remove("close");
            translateContainer.classList.remove("menu-opened");
            bodyTag.classList.remove("body-menu-opened");
          } else {
            menuTrigger.classList.add("close");
            translateContainer.classList.add("menu-opened");
            bodyTag.classList.add("body-menu-opened");
          }
        });
      }
      break;
    }
    default:
      break;
  }
};

events.forEach((eventName) => window.addEventListener(eventName, eventLogger));


